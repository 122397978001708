<template>
    <div class="main">
       <div class="header">
            <div>
                角色名称：<span>{{ titleForm.roleName }}</span>
                状态：<span>{{ titleForm.status==="1"?'有效':"无效" }}</span>
            </div>
            <div>
                <el-button type="primary" size="mini" @click="saveHandle">保存</el-button>
                <el-button  size="mini" @click="goBack">取消</el-button>
            </div>
       </div>
       <div class="content">
            <div class="content_item1">
                <ul class="content_left">
                    <li :class="{active:i===menuCurrentIndex}" v-for="(item,i) in tabMenuList" :key="i" @click="activeHandle(item,i)">
                        <el-tooltip class="item" effect="dark" :content="item.menuName" placement="top-start">
                            <span>{{item.menuName}}</span>
                        </el-tooltip>
                    </li>
                </ul>
                <div class="content_right">
                    <el-checkbox v-if="treeList.length>0" v-model="checkAll" @change="handleCheckAll" style="margin-left: 24px;margin-top: 10px;">全选</el-checkbox>
                    <el-tree
                    :data="treeList"
                    show-checkbox
                    node-key="menuCode"
                    ref="tree"
                    highlight-current
                    :default-checked-keys="defaultCheckedKeysList"
                    :props="defaultProps"
                    @check="handleCheck"
                    >
                    </el-tree>
                </div>
            </div>
            <div class="content_item2">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item label="操作备注:" prop="remark" :rules="[{ required: true, message: '请输入备注', trigger: 'blur' }]" style="width: 100%">
                        <el-input type="textarea" v-model="form.remark" @change="remarkHnadle"></el-input>
                    </el-form-item>
                </el-form>
            </div>
       </div>
    </div>
</template>
<script>
import {
  getItem,
  setItem
} from '@/utils/storage'
import { 
    getQuerySysMenuList,
    getQuerySysMenuTree,
    getSaveSysRoleMenu
} from "@/api/role.js";
import {  removeEmptyChildrenMenu,flattenTreeByIdsDetail,getNamesOfCheckedNodes,updateParentCheckState } from '@/utils/commen'
export default {
    name: "detail",
    data() {
        return {
            treeList:[],
            titleForm:{},
            tabMenuList:[],
            localstorageTreeListAll:[], // 存储所有节点的数组
            checkAll: false ,// 用于控制全选按钮的选中状态
            form:{
                remark:getItem('remark')||''
            },
           defaultCheckedKeysList:[], // 选中子节点id
           isAllChecked: false, // 用于控制全选框的选中状态
           menuCurrentIndex:getItem('menuCurrentIndex')||0,
           defaultProps: {
                children: 'children',
                label: 'label'
            },
            sysMenuId:'',
            rightDataList:[]
        };
    },
 async   created() {
        localStorage.removeItem('rightDataList')
        setItem('menuCurrentIndex',this.menuCurrentIndex)
        setItem('treeDataAll',this.localstorageTreeListAll)
        this.titleForm=this.$route.query
      await  this.getQuerySysMenuListData()
      
    },
    methods: {
        goBack(){
            this.$router.go(-1)
           this.clearLocal()
        },
        clearLocal(){
            localStorage.removeItem('menuCurrentIndex')
            localStorage.removeItem('localstorageTreeListAll')
            localStorage.removeItem('tabMenuList')
            localStorage.removeItem('rightDataList')
            localStorage.removeItem('remark')
            this.form.remark=''
        },
       async getQuerySysMenuListData(){ //左侧菜单
            try {
                let res=await getQuerySysMenuList()
                if(res.code===0){
                    this.tabMenuList=res.data
                    setItem('tabMenuList',this.tabMenuList)
                    this.sysMenuId=res.data[0].id
                    res.data.forEach(item=>{
                        this.localstorageTreeListAll.push({
                            checkAll:false,
                            list:[],
                            selectList:[]
                        })
                    })
                    setItem('localstorageTreeListAll',this.localstorageTreeListAll)
                    await  this.getQuerySysMenuTreeData(true)
                }
            } catch (error) {
                
            }
        },
        async getQuerySysMenuTreeData(type=false){ // 右侧树
            try {
                let i=getItem('menuCurrentIndex')
                this.sysMenuId=this.tabMenuList[i].id
                let res=await getQuerySysMenuTree({sysMenuId:this.sysMenuId,roleId: this.titleForm.id})
                if(res.code===0){
                    this.treeList= removeEmptyChildrenMenu(res.data)
                    this.treeList=updateParentCheckState(this.treeList)
                    // 缓存右侧数据
                    let list1=getItem('rightDataList')||[]
                    let isFlag=list1.filter(item=>item.sysMenuId===this.sysMenuId)
                   
                    let newObject = {sysMenuId:this.sysMenuId,data:this.treeList}
                    let newList=[]
                    if(isFlag.length>0){
                        const index = arr.findIndex(item => item.sysMenuId === this.sysMenuId);
                        list1= list1.splice(index, 1, newObject);

                    }else{
                        list1.push(newObject)
                    }
                    setItem('rightDataList',list1)
                    // 缓存选中的节点
                    let select2=[]
                    select2=getNamesOfCheckedNodes( this.treeList)
                    let tree= getItem('localstorageTreeListAll')||[]
                    tree[this.menuCurrentIndex].selectList=select2
                    setItem('localstorageTreeListAll',tree)
                    this.defaultCheckedKeysList=select2
                    this.$refs.tree.setCheckedKeys(select2);

                    let arr=this.treeList
                    let selectAll=this.$refs.tree.getCheckedKeys()
                    let res1= this.flattenTree(arr)
                    this.checkAll = res1.length === select2.length;
                    let dataList= flattenTreeByIdsDetail(this.treeList,select2)
                    this.setLocalstorageTreeListAll(this.checkAll,dataList)
                }
            } catch (error) {
                
            }
        },
        remarkHnadle(){ // 备注输入框值缓存
            setItem('remark',this.form.remark)
        },
        handleCheckAll(val){ // 全选按钮
            let res=[]
            if(val){
                this.$refs.tree.setCheckedNodes(this.treeList)
                let arr=this.treeList
                res= this.flattenTree(arr)
            } else{
                this.$refs.tree.setCheckedKeys([])
            }
            let dataList= flattenTreeByIdsDetail(this.treeList,res)
            this.setLocalstorageTreeListAll(val,dataList)
            let selectAll=this.$refs.tree.getCheckedKeys()
            let tree= getItem('localstorageTreeListAll')
            tree[this.menuCurrentIndex].selectList=selectAll
            setItem('localstorageTreeListAll',tree)
        },
        setLocalstorageTreeListAll(val=false,res=[]){ // 存本地存储的树形数据
            let tree= getItem('localstorageTreeListAll')
            tree[this.menuCurrentIndex].checkAll=val
            tree[this.menuCurrentIndex].list=res
            setItem('localstorageTreeListAll',tree)
        },
        handleCheck($event, data){ // 选中子节点
            let selectAll=this.$refs.tree.getCheckedKeys()
            let arr=this.treeList
            let res= this.flattenTree(arr)
            this.checkAll = selectAll.length === res.length;
            let dataList= flattenTreeByIdsDetail(arr,selectAll)
            this.setLocalstorageTreeListAll(this.checkAll,dataList)
            let tree= getItem('localstorageTreeListAll')
            tree[this.menuCurrentIndex].selectList=selectAll
            setItem('localstorageTreeListAll',tree)
        },
      async  activeHandle(row,i){ // 点击菜单
            this.menuCurrentIndex=i
            setItem('menuCurrentIndex',i)
            this.sysMenuId=this.tabMenuList[i].id
            let list1=getItem('rightDataList')||[]
            let flag=[]
            flag= list1.filter(item=>item.sysMenuId===this.sysMenuId)
           
           
            let tree= getItem('localstorageTreeListAll')||[]
            this.checkAll = tree[this.menuCurrentIndex].checkAll

            if(flag.length>0){
                this.treeList=flag[0].data
                this.defaultCheckedKeysList=tree[this.menuCurrentIndex].selectList
            }else{
                await  this.getQuerySysMenuTreeData()
            }
        },
        flattenTree(tree) { // 获取树形图中id
            if(Array.isArray(tree)){
                let result = [];
                tree.forEach(node => {
                        result.push(node.menuCode);
                    if (node.children && node.children.length) {
                        result = result.concat(this.flattenTree(node.children));
                    }
                });
                return result;
            }else{
                return tree;
            }
        },
      async  saveHandle(){ // 保存
            let remark=getItem('remark')||''
            if(!remark){
                this.$message({
                        type: 'warning',
                     message: '操作备注不能为空'
                }); 
                return false
            }
            let arr=getItem('localstorageTreeListAll')
            let leftMenu=getItem('tabMenuList')
            let str=[]
            let itemList=[]
            arr.forEach((item,index)=>{
                itemList=[]
            item?.list.length>0&&item?.list?.forEach(m=>{
                    itemList.push({
                        menuCode:m.menuCode||'',
                        menuType:m.menuType||'',
                        isCheck:true
                    })
                })
                itemList.length>0&&str.push({
                    sysMenuId:leftMenu[index].id,
                    sysMenuList:itemList
                })    
            })
            let params={
                roleId:this.titleForm.id,
                menuCode:'edit_role_menu',
                operateRemark:remark,
                menuTreeList:str
            }
            try {
                let res=await getSaveSysRoleMenu(params)
                if(res.code===0){
                    this.clearLocal()
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.$router.push({path:'/role'})
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });  
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.main{
    width:100%;
    .header{
        display:flex;
        margin: 10px;
        justify-content:space-between;
        font-size: 14px;
        span{
            margin: 0 20px;
            color: rgb(139, 139, 139);
        }
    }
    .content{ 
        width: 100%;
        min-height: 400px;
        display: flex;
        justify-content: space-between;
        .content_item1{
            border: 1px solid rgb(218, 218, 218);
            display:flex;
            width: 60%;
            .content_left{
                overflow: auto;
                width: 200px;
                font-size: 14px;
                border-right: 1px solid rgb(218, 218, 218);
                li{
                    height: 40px;
                    line-height: 40px;
                    padding-left: 10px;
                    white-space: nowrap; /* 确保文本不换行 */
                    overflow: hidden; /* 隐藏超出容器宽度的文本 */
                    text-overflow: ellipsis; /* 显示省略标记 */
                }
                li:hover{
                    cursor: pointer;
                }
                .active{
                    background: $color-theme-light;
                    transition:  .3s ease;
                }
            }
        }
        .content_item2{
                width: 40%;
                margin-right: 30px;
                padding: 10px;
                min-height: 400px;
            }   
    }
}
.content_right{
    width: 100%;
    max-height: 70vh;
    overflow: auto;
}
</style>